import axios from 'axios';
import authHeader from './auth-header';

const urls = {
  development: 'http://localhost:8080/',
  production: 'https://nifty-anthem-326801.uc.r.appspot.com/',
};

const API_URL =  urls[process.env.NODE_ENV];

class ApiAxios {
  get(endpoint, params) {
    let config = {
      headers: authHeader(),
      params
    }    
    if (params) return axios.get(API_URL + endpoint, config);
    return axios.get(API_URL + endpoint, { headers: authHeader() });
  }

  post(endpoint, params) {
    return axios.post(API_URL + endpoint, params, { headers: authHeader() });
  }

  put(endpoint, params) {
    return axios.put(API_URL + endpoint, params, { headers: authHeader() });
  }

  delete(endpoint, params) {
    return axios.delete(API_URL + endpoint, { headers: authHeader(), params });
  }
}

export default new ApiAxios();