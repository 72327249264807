<template>
  <div class="container">
    <h3>{{ content }}</h3>
  </div>
</template>

<script>

export default {
  name: "Home",
  data() {
    return {
      content: "",
    };
  },
};
</script>
