import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import auth from './middlewares/auth';

const AssociateList = () => import("./components/associate/List.vue")
const AssociateEdit = () => import("./components/associate/Edit.vue")
const AssociateNew = () => import("./components/associate/New.vue")
const ActivityList = () => import("./components/activity/List.vue")
const ActivityEdit = () => import("./components/activity/Edit.vue")
const ActivityNew = () => import("./components/activity/New.vue")
const ActivityDayList = () => import("./components/activity/DayList.vue")
const ActivityPresenceList = () => import("./components/activity/PresenceList.vue")
const ActivityManagement = () => import("./components/activity/Management.vue")
// General records
const LocationList = () => import("./components/location/List.vue")
const LocationEdit = () => import("./components/location/Edit.vue")
const LocationNew = () => import("./components/location/New.vue")
const LocationGroupList = () => import("./components/locationGroup/List.vue")
const LocationGroupEdit = () => import("./components/locationGroup/Edit.vue")
const LocationGroupNew = () => import("./components/locationGroup/New.vue")
const ModalityList = () => import("./components/modality/List.vue")
const ModalityEdit = () => import("./components/modality/Edit.vue")
const ModalityNew = () => import("./components/modality/New.vue")
const ReservationList = () => import("./components/reservation/List.vue")

// Settings
const SettingList = () => import("./components/pages/SettingList.vue")
const UserList = () => import("./components/user/List.vue")
const UserEdit = () => import("./components/user/Edit.vue")
const UserNew = () => import("./components/user/New.vue")

const Forbidden = () => import("./components/pages/Forbidden.vue")

const Profile = () => import("./components/Profile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")

const routes = [
  { path: "/", name: "home",  component: Home, beforeEnter: auth },
  { path: "/home", component: Home, beforeEnter: auth },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  // lazy-loaded
  { path: "/associates", name: "associates", component: AssociateList, beforeEnter: auth },
  { path: "/associate/new", name: "associateNew", component: AssociateNew, beforeEnter: auth },
  { path: "/associate/:id", name: "associateEdit", component: AssociateEdit, beforeEnter: auth },
  { path: "/activities", name: "activities", component: ActivityList, beforeEnter: auth },
  { path: "/activity/new", name: "activityNew", component: ActivityNew, beforeEnter: auth },
  { path: "/activity/:id", name: "activityEdit", component: ActivityEdit, beforeEnter: auth },
  { path: "/activities/daylist", name: "activitiesDayList", component: ActivityDayList, beforeEnter: auth },
  { path: "/activity/:id/presencelist", name: "activityPresenceList", component: ActivityPresenceList, beforeEnter: auth },
  { path: "/activities/management", name: "activitiesManegement", component: ActivityManagement, beforeEnter: auth },
  { path: "/locations", name: "locations", component: LocationList, beforeEnter: auth },
  { path: "/location/new", name: "locationNew", component: LocationNew, beforeEnter: auth },
  { path: "/location/:id", name: "locationEdit", component: LocationEdit, beforeEnter: auth },
  { path: "/location-groups", name: "locationGroups", component: LocationGroupList, beforeEnter: auth },
  { path: "/location-group/new", name: "locationGroupNew", component: LocationGroupNew, beforeEnter: auth },
  { path: "/location-group/:id", name: "locationGroupEdit", component: LocationGroupEdit, beforeEnter: auth },
  { path: "/modalities", name: "modalities", component: ModalityList, beforeEnter: auth },
  { path: "/modality/new", name: "modalityNew", component: ModalityNew, beforeEnter: auth },
  { path: "/modality/:id", name: "modalityEdit", component: ModalityEdit, beforeEnter: auth },
  { path: "/reservations", name: "reservations", component: ReservationList, beforeEnter: auth },
  { path: "/setting/list", name: "settingList", component: SettingList, beforeEnter: auth },  
  { path: "/users", name: "users", component: UserList, beforeEnter: auth },
  { path: "/user/new", name: "userNew", component: UserNew, beforeEnter: auth },
  { path: "/user/:id", name: "userEdit", component: UserEdit, beforeEnter: auth },
  
  //static
  { path: "/forbidden", name: "forbidden", component: Forbidden, beforeEnter: auth },  

  { path: "/profile", name: "profile", component: Profile },  
  { path: "/admin", name: "admin", component: BoardAdmin },
  { path: "/mod", name: "moderator", component: BoardModerator },
  { path: "/user", name: "user", component: BoardUser },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;