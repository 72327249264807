import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

createApp(App)
  .use(router)
  .use(store)
  .use(VueLoading)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
