<template>
  <div id="app">
    <Loading v-model:active="loadingVisible" :can-cancel="false" />
    <Navbar :currentUser="currentUser" />
    <div class="container-fluid">
      <div class="row">
        
        <Sidebar v-if="currentUser" />

        <main role="main" :class="currentUser ? 'col-md-9 ml-sm-auto col-lg-10 px-md-4' : 'col-md-12 ml-sm-auto col-lg-12 px-md-4'">
          <div class="d-flex justify-content-between flex-wrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <router-view />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from './components/partials/Navbar.vue';
import Sidebar from './components/partials/Sidebar.vue';
import VueLoading from 'vue-loading-overlay';

export default {
  components: {
    Navbar,
    Sidebar,
    Loading: VueLoading
  },
  data() {
    return {
      loadingVisible: false
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
};
</script>
