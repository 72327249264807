import ApiAxios from '../services/api-axios';
import { sAlert } from '../components/utils/jalerts';

export default function auth(to, from, next) {
  let loggedIn = localStorage.getItem('user');

  if (!loggedIn || !JSON.parse(loggedIn).auth) { next('/login'); }
  
  ApiAxios.post(`auth`, { to: to.name })
    .then(() => next() )
    .catch((e) => {
      if (e.response.status == 401) { 
        localStorage.removeItem('user');
        return next('/login');
      }
      if (e.response.status == 403) { 
        return next('/forbidden');
      }
      return sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
    });
}