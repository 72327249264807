/* eslint-disable no-alert */
/* eslint-disable camelcase */
/* eslint-disable no-tabs */
/* ========================================================================
 * Componente de dialogos e alertas.
 * Exemplos de uso:
	sDialog({
		text:'Tem certeza que deseja confirmar?',
		confirmFunction: function(){
			alert('Confimou')
		},
		closeFunction: function(){
			alert('Não')
		}
	});

	sAlert({
		type: 'success',
		text:'Essa é a exibicao de um alerta!!!',
		closeFunction: function(){
			alert('Não')
		}
	});
 * ======================================================================== */
import $ from 'jquery';

function sDialog(obj) {
  const objDefault = {
    text: '',
    confirmText: 'Sim',
    closeText: 'Não',
    showResponse: false,
  };

  const params = Object.assign(objDefault, obj);

  const jAlertBtnClose = $('<button/>', {
    class: ' btn btn-danger',
    type: 'button',
    'data-dismiss': 'modal',
  }).html(params.closeText);

  const jAlertBtnConfirm = $('<button/>', {
    class: ' btn btn-success',
    type: 'button',
  }).html(params.confirmText);

  const div_btn = $('<div/>', { class: 'text-center' })
    .append(jAlertBtnClose)
    .append('&nbsp;')
    .append(jAlertBtnConfirm);

  const textArea = $('<textarea id="sDialogTextArea" maxlength="250" rows=3 class="form-control" style="resize: none; margin: 10px 0 10px 0; " />');

  let div_response = $('<div/>', { class: 'text-center' })
    .append(textArea);

  if (!params.showResponse) { div_response = ''; }

  const modal = $('<div/>', { class: 'modal fade', 'data-backdrop': 'static', id: 'jAlertModal' });
  const modal_dialog = $('<div/>', { class: 'modal-dialog' });
  const modal_content = $('<div/>', { class: 'modal-content text-center' });
  const modal_header = $('<div/>', { class: 'modal-header' });
  const modal_body = $('<div/>', { class: 'modal-body' })
    .append(`<h4>${params.text}</h4>`)
    .append(div_response)
    .append(div_btn);
  const modal_footer = $('<div/>', { class: 'modal-footer' });

  modal_content.append(modal_header).append(modal_body).append(modal_footer).appendTo(modal_dialog);

  modal.append(modal_dialog).modal('show');

  $(jAlertBtnConfirm).click(() => {
    try {
      if (params.confirmFunction) {
        const value = $('#sDialogTextArea').val() || null;
        params.confirmFunction(value);
      } else {
        alert('Ops, erro desconhecido.');
      }
    } catch (err) {
      alert(err);  
    } finally {
      modal.modal('hide');
      modal.remove();
      $('.modal-backdrop').remove();
    }
  });

  $(jAlertBtnClose).click(() => {
    try {
      if (params.closeFunction) {
        params.closeFunction();
      }
    } catch (err) {
      alert(err);  
    } finally {
      modal.modal('hide');
      modal.remove();
      $('.modal-backdrop').remove();
    }
  });
}

function sAlert(obj) {
  const objDefault = {
    type: 'primary',
    text: '',
    closeText: 'Ok',
  };

  const params = Object.assign(objDefault, obj);

  const jAlertBtnClose = $('<button/>', {
    class: ` btn btn-${params.type}`,
    type: 'button',
    'data-dismiss': 'modal',
  }).html(params.closeText);

  const div_btn = $('<div/>', { class: 'text-center' })
    .append(jAlertBtnClose);

  const modal = $('<div/>', { class: 'modal fade', 'data-backdrop': 'static', id: 'jAlertModal' });
  const modal_dialog = $('<div/>', { class: 'modal-dialog' });
  const modal_content = $('<div/>', { class: 'modal-content text-center' });
  const modal_header = $('<div/>', { class: 'modal-header' });
  const modal_body = $('<div/>', { class: 'modal-body' })
    .append(`<h4>${params.text}</h4>`)
    .append(div_btn);
  const modal_footer = $('<div/>', { class: 'modal-footer' });

  modal_content.append(modal_header).append(modal_body).append(modal_footer).appendTo(modal_dialog);

  modal.append(modal_dialog).modal('show');

  $(jAlertBtnClose).click(() => {
    if (params.closeFunction) {
      params.closeFunction();
    }
  });
}

export {
  sAlert,
  sDialog,
};
